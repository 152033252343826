import { NgModule } from '@angular/core';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { EnvironmentService, LOCATION_TOKEN } from '@shared/services/environment/environment.service';
import { C11nIconsContainerComponent } from '@vanguard/trade-ui-components-lib-ng-18';
import {
  TdsButtonGroupModule,
  TdsButtonModule,
  TdsCardModule,
  TdsCheckboxModule,
  TdsExpansionPanelModule,
  TdsGuideletModule,
  TdsIconsModule,
  TdsModalModule,
  TdsNavbarModule,
  TdsStandardTableModule,
  TdsTablistModule,
  TdsTextInputModule,
  TdsTypeaheadModule,
} from 'vg-trade-design-system';

import * as Components from './components';
import { NgZoneUtility } from './utilities/ngZone/ngzone.utility';

@NgModule({
  declarations: [Components.AdobeAnalyticsComponent],
  imports: [
    NoopAnimationsModule,
    TdsButtonGroupModule,
    TdsButtonModule,
    TdsCardModule,
    TdsCheckboxModule,
    TdsExpansionPanelModule,
    TdsGuideletModule,
    TdsIconsModule,
    TdsModalModule,
    TdsNavbarModule,
    TdsStandardTableModule,
    TdsTablistModule,
    TdsTextInputModule,
    TdsTypeaheadModule,
    C11nIconsContainerComponent,
  ],
  exports: [
    Components.AdobeAnalyticsComponent,
    TdsButtonGroupModule,
    TdsButtonModule,
    TdsCardModule,
    TdsCheckboxModule,
    TdsExpansionPanelModule,
    TdsGuideletModule,
    TdsIconsModule,
    TdsModalModule,
    TdsNavbarModule,
    TdsStandardTableModule,
    TdsTablistModule,
    TdsTextInputModule,
    TdsTypeaheadModule,
    C11nIconsContainerComponent,
  ],
  providers: [EnvironmentService, { provide: LOCATION_TOKEN, useValue: window.location }, NgZoneUtility],
})
export class SharedModule {}
